<template>
  <div>
    <!-- {{ statusList }} -->
    <b-tabs content-class="py-3" v-model="tabIndex">
      <b-tab
        v-for="status of statusList"
        :key="status.id"
        :value="status.id"
        @click="onTabChange(status.id)"
      >
        <template #title>
          {{ status.name }}
          <b-badge pill>
            {{ status.count }}
            <span class="sr-only">count items list</span></b-badge
          >
        </template>
        <InputText
          v-model="filter.search"
          type="text"
          faIcon="search"
          className="mb-2 bg-second"
          placeholder="ค้นหาลูกค้า"
          @onClickIcon="onSearch"
          textField="telephone"
          @onEnter="onSearch"
        />
        <b-table
          striped
          hover
          :busy="isBusy"
          :items="documentList"
          :fields="fields"
        >
          <template v-slot:cell(created_time)="data">
            <div class="text-center text-black my-2">
              {{ $moment(data.item.created_time).format("DD/MM/YYYY") }}
              <br />
              {{ $moment(data.item.created_time).format("HH:mm") }}
            </div>
          </template>
          <template v-slot:cell(action)>
            <font-awesome-icon icon="clipboard" title="detail" class="mr-2" />
            <font-awesome-icon icon="pencil-alt" title="detail" />
            <font-awesome-icon icon="ellipsis-v" title="detail" class="ml-2" />
          </template>
          <template v-slot:table-busy>
            <div class="text-center text-black my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong class="ml-2">Loading...</strong>
            </div>
          </template>
        </b-table>
        <Pagination
          @handleChangeTake="handleChangeTake"
          :pageOptions="pageOptions"
          :filter="filter"
          :rows="rows"
          @pagination="pagination"
        />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";
import InputText from "@/components/inputs/InputText";
export default {
  components: {
    Pagination,
    InputText,
  },
  data() {
    return {
      filter: {
        search: "",
        inventory_transfer_document_id: this.$route.params.id,

        inventory_transfer_status: [], //null == ทั้งหมด

        page: 1,
        take: 10,
      },
      pageOptions: [
        { value: 5, text: "5 / page" },
        { value: 10, text: "10 / page" },
        { value: 30, text: "30 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" },
      ],
      rows: 0,
      statusList: [],
      documentList: [],
      fields: [
        { label: "วันที่", key: "created_time" },
        { label: "เลขที่เอกสาร", key: "document_no" },
        { label: "สถานะ", key: "status_name" },
        { label: "ต้นทาง", key: "source_branch.branch_name" },
        { label: "ปลายทาง", key: "destination_branch.branch_name" },
        { label: "หมายเหตุ", key: "note" },
        { label: "", key: "action" },
      ],
      tabIndex: 1,
      isBusy: false,
    };
  },
  watch: {
    tabIndex(val) {
      console.log(val);
    },
  },
  created() {
    this.getData();
    this.getStatusList();
  },
  methods: {
    async getData() {
      this.isBusy = true;
      //   this.filter.inventory_transfer_status = [val];
      const res = await this.axios.post(
        `${this.$baseUrl}/Inventory/Transfer/List`,
        this.filter
      );
      console.log(res);
      this.rows = res.detail.total_count;
      this.documentList = res.detail.transfer_list;
      this.isBusy = false;
    },

    async getStatusList() {
      const res = await this.axios.post(
        `${this.$baseUrl}/Inventory/Transfer/ListStatus`,
        this.filter
      );
      console.log(res);
      this.statusList = res.detail;
    },
    handleChangeTake() {},
    pagination() {},
    onSearch() {},
    onTabChange(val) {
      this.getData(val);
    },
  },
};
</script>

<style></style>
